






































































import FormsHelper from "@/includes/logic/Forms/FormsHelper"
import chatConfigMixin                   from "@/mixins/chatConfig";
import { InputSetups } from "@/mixins/input-setups";
import AddAdmin from "../AddAdmin/AddAdmin.vue";
import { AddAdminType } from "@/includes/types/AddAdmin/Enums";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from "piramis-base-components/src/components/HighlightAnchor.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'mixins': [
    chatConfigMixin,
  ],
  'components': {
    EmptyData,
    ConfigField,
    HighlightAnchor,
    AddAdmin
  },
  data() {
    return {
      EntityTypes,
      AddAdminType
    }
  }
})
export default class ProtectionTypeForm extends Mixins<UseFields, InputSetups, FormsHelper>(UseFields, InputSetups, FormsHelper) {
  isFormsExists = true

  async created(): Promise<void> {
    const forms = await this.getForms()
    this.isFormsExists = forms && forms.length > 0
  }

  goToForms({ label }: { label: string }): void {
    this.$router.push({
      name: 'questionnaire_form',
      params: {
        [EntityTypes.CHAT_ID]: this.$route.params[EntityTypes.CHAT_ID],
        actionType: 'new',
      },
      query: {
        initName: label
      }
    })
  }
}
