import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class CreateTriggerModalMixin extends Vue {

  isOpenCreateTriggerModal = false

  initTriggerName = ''

  handleCreateTriggerButton(option?:Pick<SelectOption, 'label'>):void {
    if (option) {
      this.initTriggerName = option.label
    }

    this.isOpenCreateTriggerModal = true
  }
}
