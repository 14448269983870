















import { Component, Mixins } from 'vue-property-decorator'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { InputSetups } from "@/mixins/input-setups";

@Component
export default class InviteValidation extends Mixins(UseFields, InputSetups) {

}
