




















































































































































































































































































































































































































































































































































































































import Placeholders from '@/mixins/placeholders/placeholders'
import ChannelBinding from '@/components/chat/ChannelBinding.vue'
import ProtectionTypeForm from '@/components/chat/ProtectionTypeForm.vue'
import VariableCapcha from '@/components/chat/VariableCapcha.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import InviteValidation from '@/components/chat/InviteValidation.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import CreateTriggerModal from '@/components/CreateTriggerModal/CreateTriggerModal.vue'
import CreateTriggerModalMixin from '@/components/CreateTriggerModal/CreateTriggerModalMixin'
import GreetingPageHelpMessage from '@/components/HelpViews/Greeting/GreetingPageHelpMessage.vue'
import GreetingHelloMessageHelpView from '@/components/HelpViews/Greeting/GreetingHelloMessageHelpView.vue'
import GreetingEnterProtectionTypeValueHelpView
  from '@/components/HelpViews/Greeting/GreetingEnterProtectionTypeValueHelpView.vue'
import GreetingLeaveMemberMessageHelpView from '@/components/HelpViews/Greeting/GreetingLeaveMemberMessageHelpView.vue'
import GreetingChatButtonsMessageHelpView from '@/components/HelpViews/Greeting/GreetingChatButtonsMessageHelpView.vue'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { TagData } from 'piramis-base-components/src/components/Tags/types'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import MiniHelpMessage from 'piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { atSignedLogin, tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import { snakeCase } from 'lodash'
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import ModulesScreensWrapper from "@/components/Modules/modules/ModulesScreensWrapper.vue";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import NewUsersHandlerModule
  from '@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/NewUsersHandlerModule'
import {
  InviteValidationGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection/ReputationCalculationMode";
import { isNewChatConfig } from '@/includes/types/Chat/predicates'

@Component({
  'components': {
    ModulesScreensWrapper,
    CreateTriggerModal,
    ValidationObserver,
    ChannelBinding,
    NotAvailableOptionsOverlay,
    VariableCapcha,
    ProtectionTypeForm,
    InviteValidation,
    CenteredColumnLayout,
    PageTitle,
    Tags,
    MiniHelpMessage,
  },
  'methods': {
    getTariffTranslationKey
  },
  'data': () => ({
    StepUnit,
    MediaEditorMode,
    snakeCase,
    GreetingPageHelpMessage,
    GreetingHelloMessageHelpView,
    GreetingEnterProtectionTypeValueHelpView,
    GreetingLeaveMemberMessageHelpView,
    GreetingChatButtonsMessageHelpView,
    EntityTypes
  })
})
export default class GreetingPage extends Mixins(UseFields, TariffsTagsHelper, InputSetups, Placeholders, CreateTriggerModalMixin) {
  premiumProtectionTypesList: Array<string> =
    [ 'BindedChannel', 'VariableCaptcha', 'MathCaptcha', 'FormWithValidation', 'FormWithoutValidation' ]

  currentView = 0

  greetingModules: Array<ModuleTypesEnum> = [
    ModuleTypesEnum.LeaveUsersHandlerModule,
    ModuleTypesEnum.NewUserDeprecatedTriggersHandlerModule,
    ModuleTypesEnum.NewUserTriggersHandlerModule,
    ModuleTypesEnum.NewUsersHandlerModule,
    ModuleTypesEnum.SilentAdminInviteHandlerModule,
    ModuleTypesEnum.SilentInviteHandlerModule,
  ]

  get dontAcceptJoinRequests() {
    return !this.$store.state.chatState.chat.config.dont_accept_join_requests
  }

  set dontAcceptJoinRequests(value: boolean) {
    this.$store.state.chatState.chat.config.dont_accept_join_requests = !value
  }

  checkLicense(): Array<TagData> | null {
    return !this.$store.getters.isChatLicenseExists ? [ { text: 'Starter', color: 'rgb(181, 192, 212)' } ] : null
  }

  get protectionTypeIsNotInviteValidation() {
    const module = ModuleManagerState?.modules.find(m => m instanceof NewUsersHandlerModule)

    if (module) {
      return !((module as NewUsersHandlerModule).params.protection instanceof InviteValidationGreetingProtection)
    }

    return false
  }

  @Watch('$store.state.chatState.chat.config.enter_protection_type')
  onEnterProtectionTypeChange(value: any): void {
    if (this.$store.getters.isChatSet) {
      this.$store.commit('EXEC_CALLBACK_IN_STORE', {
        'callback': () => {
          this.$store.state.chatState.chat.config.check_new_users = value !== 'None'
        }
      })
    }
  }

  get bindChannelInfo(): string {
    if(this.$store.state.chatState.chat.brand) {
      return this.$t('bind_channel_post_alert_greeting', { '0': atSignedLogin(this.$store.state.chatState.chat.brand.botname), '1': tgLoginLink(this.$store.state.chatState.chat.brand.botname) }).toString()
    } else {
      return this.$t('bind_channel_post_alert_greeting', { '0': `@ChatKeeperBot`, '1': `https://t.me/chatkeeperbot` }).toString()
    }
  }

  isAvailableProtectionType(type: string): boolean {
    return !(!this.getTagsByFieldKey(`binded_channel`)
      ? false : this.premiumProtectionTypesList.includes(type))
  }

  getProtectionTypeTags(type: string): Array<TagData> {
    const statReferralsTag = this.getTagsByFieldKey('stat_referrals')

    return [
      ...(this.premiumProtectionTypesList.includes(type) && statReferralsTag !== null ?
        statReferralsTag : [])
    ]
  }

  getProtectionTypes(): Promise<Array<SelectOptionData>> {
    const options: Array<SelectOptionData> = [
      {
        label: this.$t('channel_binding_protection_type_null') as string,
        value: 'None',
      },
      {
        label: this.$t('channel_binding_protection_type_standart') as string,
        value: 'Standart',
      },
      {
        label: this.$t('channel_binding_protection_type_invite_validation') as string,
        value: 'InviteValidation',
      },
      {
        label: this.$t('channel_binding_protection_type_binded_channel') as string,
        value: 'BindedChannel',
      },
      {
        label: this.$t('protection_type_variable_capcha') as string,
        value: 'VariableCaptcha',
      },
      {
        label: this.$t('protection_type_math_capcha') as string,
        value: 'MathCaptcha',
      },
      {
        label: this.$t('protection_type_form_with_validation') as string,
        value: 'FormWithValidation',
      },
      {
        label: this.$t('protection_type_form_without_validation') as string,
        value: 'FormWithoutValidation',
      },
    ]

    options.forEach((option) => {
      option.disabled = !this.isAvailableProtectionType(option.value)
      option.tags = this.getProtectionTypeTags(option.value)
    })

    return Promise.resolve(options)
  }

  get protectionType(): string {
    return this.$store.state.chatState.chat.config.enter_protection_type
  }

  get hasGreeting() {
    const helloMessage = this.stateChatConfigPath.hello_message

    return helloMessage.length > 0 && helloMessage.some((m:any) => m.text.length && m.text !== '\n' || !!m.attachments.length)
  }

  get enterProtectionTypeValue(): string {
    if (![ 'Standart', 'InviteValidation', 'BindedChannel', 'VariableCaptcha', 'MathCaptcha', 'FormWithValidation', 'FormWithoutValidation' ]
      .includes(this.$store.state.chatState.chat.config.enter_protection_type)) {
      this.$store.commit('pi/EXEC', {
        'fn': () => {
          this.$store.state.chatState.chat.config.check_new_users = false
          this.$store.state.chatState.chat.config.enter_protection_type = 'None'
        },
      })
      return 'None'
    }
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.check_new_users = true
      },
    })
    return this.$store.state.chatState.chat.config.enter_protection_type
  }

  set enterProtectionTypeValue(value: string) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.enter_protection_type = value
      },
    })
  }

  /*check if key of _new exists*/
  isTriggerSet(key: string): boolean {
    return this.$store.state.chatState.chat.config[key] && !!this.$store.state.chatState.chat.config[key].length
  }

  get getPiramisSpamDetectionModes(): Array<SelectOptionData> {
    return [
      { 'label': this.$t('piramis_spam_detection_modes_none').toString(), 'value': 'None' },
      { 'label': this.$t('piramis_spam_detection_modes_log').toString(), 'value': 'Log' },
      { 'label': this.$t('piramis_spam_detection_modes_mark').toString(), 'value': 'Mark' },
      { 'label': this.$t('piramis_spam_detection_modes_ban').toString(), 'value': 'Ban' },
    ]
  }

  get isNewChatConfigVm() {
    return isNewChatConfig(this.$store.state.chatState.chat)
  }

  created() {
    if (this.isNewChatConfigVm) {
      this.$router.replace({ name: "ActiveModules" })
    }
  }
}
