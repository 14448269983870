var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"protection-type-form"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isFormsExists)?_c('div',[_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'enter_protection_form',
            'taggable': true,
            'createButton': {
              'title': 'Создать форму',
              'callback': _vm.goToForms,
            },
          }
        }}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$store.state.chatState.chat.config.enter_protection_form)?_c('highlight-anchor',{staticClass:"my-3 py-1",attrs:{"has-anchor-icon":"","anchor-key":"enterProtectionFormUsers"}},[_c('config-field',{staticClass:"mx-1",attrs:{"title":_vm.$t('field_enter_protection_form_users_title'),"mini-help-message":_vm.$t('field_enter_protection_form_users_mini_help_message')}},[_c('add-admin',{attrs:{"add-type":_vm.AddAdminType.Enter,"users-path":{
                model: _vm.stateChatPath,
                key: 'enterProtectionFormUsersMap'
              }}})],1)],1):_vm._e()],1)],1):_c('div',{staticClass:"flex flex-col p-2"},[_c('empty-data',{scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('protection_type_form_forms_list_empty'))+" ")]},proxy:true}])}),_c('div',{staticClass:"flex flex-row items-center justify-center mt-2"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.goToCreateNewFormPage(parseInt(_vm.$route.params[_vm.EntityTypes.CHAT_ID]))}}},[_vm._v(" "+_vm._s(_vm.$t('protection_type_form_create_new_form'))+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }