











import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  data() {
    return {
      EntityTypes
    }
  }
})
export default class GreetingEnterProtectionTypeValueHelpView extends Vue {
}
