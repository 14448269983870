




























































enum ModalStep {
  SelectType,
  SetName,
}

import { BaseViewActionType } from '@/includes/types/Enums'
import { TriggerSettingType } from '@/includes/types/triggers/Enums'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      BaseViewActionType,
      TriggerSettingType,
      ModalStep
    }
  }
})
export default class CreateTriggerModal extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel({ type: Boolean, default: false, required: true }) isModalOpen!: boolean

  @Prop({ type: String, default: '', required: false }) initName!:string | null

  @Emit('resetInit')
  resetInitTriggerName():string {
    return ''
  }

  private name = ''

  get triggerName():string {
    if (this.initName) {
      this.name = this.initName
      this.resetInitTriggerName()
    }

    return this.name
  }

  set triggerName(value:string) {
    this.name = value
  }

  step: ModalStep = ModalStep.SelectType

  type: TriggerSettingType | null = null

  get triggerModalTitle():string {
    if (this.step === ModalStep.SelectType) {
      return this.$t('create_trigger_empty_modal').toString()
    }

    return this.$t(`create_${ this.type!.toLowerCase() }_trigger_empty_modal`).toString()
  }

  resetFields():void {
    this.step = ModalStep.SelectType
    this.triggerName = ''
    this.type = null
  }

  onCancel():void {
    this.isModalOpen = false
    this.resetFields()
  }

  selectTriggerType(type:TriggerSettingType):void {
    this.type = type
    this.step = ModalStep.SetName
  }

  handleOk(): void {
    this.$router.push({
      name: 'chat_trigger',
      params: {
        actionType: BaseViewActionType.New
      },
      query: {
        type: this.type,
        name: this.triggerName
      }
    })
  }
}
