




























import { InputSetups } from "@/mixins/input-setups";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'

import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'

@Component
export default class VariableCapcha extends Mixins(UseFields, InputSetups) {
  @Ref('enterProtectionVariant') readonly enterProtectionVariant!: SelectInput

  @Watch('$store.state.chatState.chat.config.enter_protection_variants')
  onProtectionVariantsChange(): void {
    this.enterProtectionVariant.getOptions()
  }

  getProtectionVariants(): Promise<Array<FieldData>> {
    return Promise.resolve(this.$store.state.chatState.chat.config.enter_protection_variants
      .map((value: any) => ({ 'label': value, value })))
  }
}
